import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Navigation from '../Navigation/Navigation';

import styles from './Header.module.scss';

type Props = {
  hasMargin: boolean;
  isNavOpen: boolean;
  location: string;
  siteTitle: string;
};

const Header: React.FunctionComponent<Props> = ({
  hasMargin,
  isNavOpen,
  location,
  siteTitle,
  toggleNav,
}) => (
  <div className={classNames(styles.wrapper, hasMargin ? styles.margin : null)}>
    <header className={styles.header}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <Link className={styles.titleLink} to="/">
            <span>F1online</span>.tv
          </Link>
        </h1>
        <div className={styles.navigation}>
          <div className={styles.navigationWrapper}>
            <Navigation
              isNavOpen={isNavOpen}
              location={location}
              menu={[
                {
                  key: 'home',
                  path: '/',
                  title: 'Home',
                },
                {
                  key: 'seasons',
                  path: '/seasons/',
                  title: 'Seasons',
                },
                /*{
                                     key: 'tags',
                                     path: '/tags',
                                     title: 'Tags',
                                   },*/
              ]}
              toggleNav={toggleNav}
            />
          </div>
        </div>
      </div>
    </header>
  </div>
);

export default Header;
