import React, { memo } from 'react';
import classNames from 'classnames';

import NavigationItem from './NavigationItem/NavigationItem';
import NavigationToggle from './NavigationToggle/NavigationToggle';

import styles from './Navigation.module.scss';

const Navigation = ({
  isNavOpen,
  location,
  menu,
  toggleNav,
}) => (
  <div className={styles.wrapper}>
    <NavigationToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />

    <nav className={classNames(styles.menu)}>
      <div className={styles.header}>
      </div>
      <ul className={styles.list}>
        {menu.map(link => (
          <NavigationItem key={link.key} path={link.path} title={link.title} toggleNav={toggleNav} />
        ))}
      </ul>
    </nav>
  </div>
);

export default memo(Navigation);
