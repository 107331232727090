import React from 'react';
import { Link } from 'gatsby';

import styles from './NavigationItem.module.scss';

const NavigationItem = ({ path, title, toggleNav }) => (
  <li className={styles.wrapper}>
    <Link
      className={styles.link}
      onClick={() => toggleNav()}
      to={Array.isArray(path) ? path[0] : path}
    >
      {title}
    </Link>
  </li>
);

export default NavigationItem;
