/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import styles from './PageLayout.module.scss';

import Header from '../Header/Header';

// import global styles
import '../../styles/global/styles.scss';

const PageLayout = ({ children, hasHeaderMargin }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleNav = () => setIsNavOpen(!isNavOpen);
  //const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const handleClose = () => {
    setIsNavOpen(false);
    setIsSearchOpen(false);
  };

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={classNames(
      styles.container,
      isNavOpen && styles.nav,
      isSearchOpen && styles.search
    )}>
      <div className={styles.wrapper}>
        <Header
          hasMargin={hasHeaderMargin}
          isNavOpen={isNavOpen}
          toggleNav={toggleNav}
          siteTitle={data.site.siteMetadata.title}
        />
        <main
          className={classNames(
            styles.main,
            (isNavOpen || isSearchOpen) && styles.fade
          )}
          onClick={handleClose}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
